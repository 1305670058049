<template>
  <NotificationBase
    :notification
    :link="'/bonuses'"
    :icon="{ name: 'freespins' }"
    :compact
  >
    <template #subject>
      {{ t('notifications.subject.freespinsAccrual') }}
    </template>
    <i18n-t
      keypath="notifications.body.freespinsAccrual"
      tag="p"
      :plural="count"
    >
      <template #count>
        <b>{{ count }}</b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { FreespinProgramCreated } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: FreespinProgramCreated
  compact?: boolean
}>()

const { t } = useI18n()

const count = computed(() => notification.data.freespinNumber)
</script>
